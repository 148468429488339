export const menuItems = [
    {
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 2,
        label: 'menuitems.profile.text',
        icon: 'ri-user-3-line',
        link: '/profile'
        
    },
    {
        id: 3,
        label: 'menuitems.classroutine.text',
        icon: 'ri-calendar-event-line',
        link: '/classroutine'
     
    },
    {
        id: 4,
        label: 'menuitems.exam.text',
        icon: 'ri-todo-line',
        link: '/exam'    
    },
    {
        id: 5,
        label: 'menuitems.result.text',
        icon: 'ri-file-chart-line',
        link: '/result'
   
    },
    {
        id: 7,
        label: 'menuitems.notifications.text',
        icon: 'ri-notification-3-line',
        link: '/notifications'
   
    },
    {
        id: 8,
        label: 'menuitems.group.text',
        icon: 'ri-building-line',
        link: '/group'
   
    },
    {
        id: 9,
        label: 'menuitems.onlinecourse.text',
        icon: 'ri-mac-line',
        link: '/onlinecourse'
   
    },
    {
        id: 10,
        label: 'menuitems.feepayment.text',
        icon: 'ri-wallet-3-line',
        link: '/payment'
   
    }
]