export const menuItems = [
    {
        id: 1,
        label: 'menuitems.dashboard.text',
        icon: 'ri-dashboard-line',
        link: '/'
    },
    {
        id: 2,
        label: 'menuitems.uielements.text',
        icon: 'ri-pencil-ruler-2-line',
        link: '/'
        
    },
    {
        id: 3,
        label: 'menuitems.apps.text',
        icon: 'ri-apps-2-line',
        link: '/'
     
    },
    {
        id: 4,
        label: 'menuitems.components.text',
        icon: 'ri-stack-line',
        link: '/'
    
    },
    {
        id: 5,
        label: 'menuitems.pages.text',
        icon: 'ri-file-copy-2-line',
        link: '/'
   
    }
]